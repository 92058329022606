import { ref as f, readonly as C } from "vue";
var I = ((o) => (o[o.Customer = 1] = "Customer", o[o.Consumer = 2] = "Consumer", o[o.ConsumerERS = 3] = "ConsumerERS", o))(I || {}), d = { Info: "info" }, N = { Toaster: "toaster" };
const t = f([]), a = f(0), E = 6e3;
function O() {
  const o = ({
    id: i,
    variant: e = d.Info,
    canClose: c,
    withShadow: l,
    body: v,
    title: m,
    timeout: r = E,
    location: T = N.Toaster
  }) => {
    let n = i;
    n || (a.value++, n = a.value.toString()), t.value.unshift({
      id: n,
      body: v,
      variant: e,
      title: m,
      timeout: r,
      location: T,
      canClose: c,
      withShadow: l
    }), r && setTimeout(() => {
      s(n);
    }, r);
  }, s = (i) => {
    t.value = t.value.filter((e) => e.id !== i);
  }, u = () => {
    t.value = [];
  };
  return {
    notifications: C(t),
    addNotification: o,
    removeNotification: s,
    clearNotifications: u
  };
}
export {
  O as useNotification
};
